.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid {
  display: grid;
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.place-content-center {
  place-content: center;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.m-4 {
  margin: 1rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.p-05 {
  padding: 0.125rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-1-5 {
  padding: 0.375rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.border-05 {
  border-width: 0.5px;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-grey-50 {
  border-style: solid !important;
  border-color: var(--grey-50) !important;
}

.border-grey-100 {
  border-style: solid !important;
  border-color: var(--grey-100) !important;
}

.border-grey-200 {
  border-style: solid !important;
  border-color: var(--grey-200) !important;
}

.border-grey-300 {
  border-style: solid !important;
  border-color: var(--grey-300) !important;
}

.border-grey-400 {
  border-style: solid !important;
  border-color: var(--grey-400) !important;
}

.border-grey-500 {
  border-style: solid !important;
  border-color: var(--grey-500) !important;
}

.border-grey-600 {
  border-style: solid !important;
  border-color: var(--grey-600) !important;
}

.border-grey-700 {
  border-style: solid;
  border-color: var(--grey-700);
}

.bg-grey-50 {
  background-color: #fafafa;
}

.bg-grey-50 {
  background-color: #fafafa;
}

.bg-grey-100 {
  background-color: var(--grey-100) !important;
}

.bg-grey-200 {
  background-color: var(--grey-200) !important;
}

.bg-grey-300 {
  background-color: var(--grey-300) !important;
}

.bg-grey-400 {
  background-color: var(--grey-400) !important;
}

.bg-grey-500 {
  background-color: var(--grey-500) !important;
}

.bg-grey-600 {
  background-color: var(--grey-600) !important;
}

.bg-grey-700 {
  background-color: var(--grey-700) !important;
}

.bg-grey-800 {
  background-color: var(--grey-800) !important;
}

.bg-dark-grey {
  background-color: rgba(255, 255, 255, 0.03);
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-md {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 1rem;
}

.rounded-2xl {
  border-radius: 2rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

/* tailwind-css classes */

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-36 {
  height: 9rem;
}

.size-1 {
  width: 0.25rem;
  height: 0.25rem;
}

.size-2 {
  width: 0.5rem;
  height: 0.5rem;
}

.size-3 {
  width: 0.75rem;
  height: 0.75rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.text-grey-100 {
  color: var(--grey-100);
}

.text-grey-200 {
  color: var(--grey-200);
}

.text-grey-300 {
  color: var(--grey-300);
}

.text-grey-400 {
  color: var(--grey-400);
}

.text-grey-500 {
  color: var(--grey-500);
}

.text-grey-600 {
  color: var(--grey-600);
}

.text-grey-700 {
  color: var(--grey-700);
}

.text-grey-800 {
  color: var(--grey-800);
}

.text-grey-900 {
  color: var(--grey-900);
}

.text-dark {
  color: var(--dark-primary);
}

.text-xxs {
  font-size: 11px;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.left-0 {
  left: 0;
}

.-right-1 {
  right: -0.25rem;
}

.-right-2 {
  right: -0.5rem;
}

.-right-3 {
  right: -0.75rem;
}

.-right-4 {
  right: -1rem;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 0.25rem;
}

.right-2 {
  right: 0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.right-4 {
  right: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-3 {
  bottom: 0.75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.relative {
  position: relative;
}

.font-medium {
  font-family: 'PoppinsMedium';
}

.font-semibold {
  font-family: 'PoppinsSemiBold';
}

.italic {
  font-style: italic;
}

.bg-transparent {
  background-color: transparent;
}

.bg-dark-primary {
  background-color: var(--dark-primary);
}

.bg-dark-secondary {
  background-color: rgba(255, 255, 255, 0.1);
}

.border-0 {
  border: none;
}

.text-white {
  color: white;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-auto {
  margin-right: auto;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.bg-orange-200 {
  background-color: #ffedd5;
}

.bg-orange-300 {
  background-color: var(--orange-300);
}

.bg-orange-400 {
  background-color: var(--orange-400);
}

.bg-orange-500 {
  background-color: var(--orange-500);
}

.text-orange-500 {
  color: #f97316;
}

.text-orange-600 {
  color: var(--orange-600);
}

.text-orange-700 {
  color: var(--orange-700);
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

/* pulse animation */

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.input-base {
  border: none;
  outline: none;
}

.input-base::placeholder {
  font-size: 12px;
}

.input-base-placeholder::placeholder {
  font-size: 12px;
  color: var(--grey-400);
}

.card-options {
  visibility: hidden;
  transition: all 0.1s linear;
}

.parent-card:hover .card-options {
  visibility: visible;
}

.card-options2 {
  opacity: 0;
  transition: all 0.1s linear;
  transform: translateY(20%);
}

.parent-card:hover .card-options2 {
  opacity: 1;
  transform: translateY(0%);
}

.button-base {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: all 0.1s ease;
}

.button-base:hover {
  /* background opacity */
  opacity: 0.85;
}

.button-base-dark {
  outline: none;
  background: var(--grey-800);
  cursor: pointer;
  transition: all 0.1s ease;
  border: none;
}
.button-base-dark:hover {
  background-color: var(--grey-700);
}

.font-poppins {
  font-family: 'PoppinsRegular', sans-serif;
}

.border-box {
  box-sizing: border-box;
}

.w-fit {
  width: fit-content;
}

.card-empty:empty {
  display: none;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 6px;
  display: grid;
  place-content: center;
}

.icon-btn:hover {
  background-color: var(--grey-100);
}

.icon-btn:disabled {
  color: #00000042;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* table */

.border-b-05 {
  border-bottom: 0.5px solid var(--grey-200);
}

.border-b-1 {
  border-bottom: 1px solid var(--grey-200);
}

.border-t-05 {
  border-top: 0.5px solid var(--grey-200);
}

.border-t-1 {
  border-top: 1px solid var(--grey-200);
}

.text-capitalize {
  text-transform: capitalize;
}

.row {
  transition: all 0.1s ease;
  cursor: pointer;
}

.row:hover {
  transition: all 0.1s ease;
  background-color: var(--grey-50);
}

.checkbox-ui {
  appearance: none;
}

.list-bullets {
  padding: 0px 30px;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-60 {
  z-index: 60;
}

.z-70 {
  z-index: 70;
}

.z-80 {
  z-index: 80;
}

.z-90 {
  z-index: 90;
}

.z-100 {
  z-index: 99999;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

/* parent has less than 2 child css hide more-tag */

.parent-tag-containter > *:nth-last-child(n + 2) + .more-tag {
  display: none;
}
/* hide value-tag if parent-tag has more than 2 childs */

.parent-tag-containter:has(> *:nth-child(2)) :nth-child(n + 3) {
  display: none;
}
/* hide more-tag if it is not 3rd or greater child */

.parent-tag-containter > *:nth-child(n + 4) .more-tag {
  display: none;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.leading-3 {
  line-height: 0.75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.hideScrollbar {
  scrollbar-width: none;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.shadow-none {
  box-shadow: none;
}

.bg-white {
  background-color: white;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hover-bg-grey-50 {
  transition: background-color 0.3s ease; /* Optional: adds a smooth transition effect */
}

.hover-bg-grey-50:hover {
  background-color: var(--grey-50); /* Equivalent to Tailwind's grey-50 */
}

.hover-bg-grey-100 {
  transition: background-color 0.3s ease; /* Optional: adds a smooth transition effect */
}

.hover-bg-grey-100:hover {
  background-color: var(--grey-100); /* Equivalent to Tailwind's grey-100 */
}

.rotate-15 {
  transform: rotate(15deg);
}

.rotate-30 {
  transform: rotate(30deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-60 {
  transform: rotate(60deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-360 {
  transform: rotate(360deg);
}

.outline-none {
  outline: none;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-wrap {
  overflow-wrap: anywhere;
}

/* .hide-last-child {
  display: inline;
}

.hide-last-child:last-child {
  display: none;
} */

.noto-sans-400 {
  font-family: 'Noto Sans', serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wght' 400;
}

.noto-sans-600 {
  font-family: 'Noto Sans', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: 'wght' 600;
}

.border-dashed {
  border-style: dashed;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

.containerCard:hover {
  background: var(--grey-100);
}

.bg-red-50 {
  background-color: var(--red-50);
}

.bg-red-100 {
  background-color: var(--red-100);
}

.bg-red-200 {
  background-color: var(--red-200);
}

.bg-red-300 {
  background-color: var(--red-300);
}

.bg-red-400 {
  background-color: var(--red-400);
}

.bg-red-500 {
  background-color: var(--red-500);
}

.bg-red-600 {
  background-color: var(--red-600);
}

.bg-red-700 {
  background-color: var(--red-700);
}

.bg-red-800 {
  background-color: var(--red-800);
}

.bg-red-900 {
  background-color: var(--red-900);
}

.text-red-50 {
  color: var(--red-50);
}

.text-red-100 {
  color: var(--red-100);
}

.text-red-200 {
  color: var(--red-200);
}

.text-red-300 {
  color: var(--red-300);
}

.text-red-400 {
  color: var(--red-400);
}

.text-red-500 {
  color: var(--red-500);
}

.text-red-600 {
  color: var(--red-600);
}

.text-red-700 {
  color: var(--red-700);
}

.text-red-800 {
  color: var(--red-800);
}

.text-red-900 {
  color: var(--red-900);
}

.bg-green-50 {
  background-color: var(--green-50);
}

.bg-green-100 {
  background-color: var(--green-100);
}

.bg-green-200 {
  background-color: var(--green-200);
}

.bg-green-300 {
  background-color: var(--green-300);
}

.bg-green-400 {
  background-color: var(--green-400);
}

.bg-green-500 {
  background-color: var(--green-500);
}

.bg-green-600 {
  background-color: var(--green-600);
}

.bg-green-700 {
  background-color: var(--green-700);
}

.bg-green-800 {
  background-color: var(--green-800);
}

.bg-green-900 {
  background-color: var(--green-900);
}

.text-green-50 {
  color: var(--green-50);
}

.text-green-100 {
  color: var(--green-100);
}

.text-green-200 {
  color: var(--green-200);
}

.text-green-300 {
  color: var(--green-300);
}

.text-green-400 {
  color: var(--green-400);
}

.text-green-500 {
  color: var(--green-500);
}

.text-green-600 {
  color: var(--green-600);
}

.text-green-700 {
  color: var(--green-700);
}

.text-green-800 {
  color: var(--green-800);
}

.text-green-900 {
  color: var(--green-900);
}

.bg-indigo-500 {
  background-color: #6366f1;
}

.rpv-search__highlight {
  background-color: rgba(255, 165, 0, 0.5);
}

.softShadow {
  box-shadow: 0 3px 5px 0 rgb(102 102 102 / 10%);
}

.font-noto-sans {
  font-family: 'Noto Sans', sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bg-orange-50 {
  background-color: var(--orange-50);
}

.bg-orange-100 {
  background-color: var(--orange-100);
}

.bg-orange-200 {
  background-color: var(--orange-200);
}

.bg-orange-300 {
  background-color: var(--orange-300);
}

.bg-orange-400 {
  background-color: var(--orange-400);
}

.bg-orange-500 {
  background-color: var(--orange-500);
}

.bg-orange-600 {
  background-color: var(--orange-600);
}

.bg-orange-700 {
  background-color: var(--orange-700);
}

.bg-orange-800 {
  background-color: var(--orange-800);
}

.text-orange-50 {
  color: var(--orange-50);
}

.text-orange-100 {
  color: var(--orange-100);
}

.text-orange-200 {
  color: var(--orange-200);
}

.text-orange-300 {
  color: var(--orange-300);
}

.text-orange-400 {
  color: var(--orange-400);
}

.text-orange-500 {
  color: var(--orange-500);
}

.text-orange-600 {
  color: var(--orange-600);
}

.text-orange-700 {
  color: var(--orange-700);
}

.text-orange-800 {
  color: var(--orange-800);
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-green-500 {
  background-color: #10b981;
}

.bg-yellow-500 {
  background-color: #f59e0b;
}

.bg-purple-500 {
  background-color: #8b5cf6;
}
